import { React } from "react";

import { Navbar, Nav, NavItem } from "react-bootstrap";
import "./Navigator.css";

function Navi() {
  return (
    <Navbar fixed="top" className="navMain" dir="rtl">
      <Navbar.Brand className="nav-text">
        <i className="em em-flag-ps flag-icon"></i>
        الاقصى ينادينا ✌️ ✌️ ✌️
      </Navbar.Brand>
      <Nav className="mr-auto nav-text">
        <NavItem className="city-select"></NavItem>
      </Nav>
    </Navbar>
  );
}

export default Navi;
