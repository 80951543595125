import { React, useState, useEffect } from "react";

import { Row, Col, Jumbotron } from "react-bootstrap";
import "./Navigator.css";
import "./ClockHero.css";
import axios from "axios";
import clip from "./clip.mp4";

function ClockHero({ city }) {
  const [date, setDate] = useState(new Date());
  const [hijriDate, setHijriDate] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        `https://api.aladhan.com/v1/timingsByCity?city=${city}&country=Israel&method=8`
      );
      setHijriDate(res.data.data.date.hijri.date);
    };
    getData();
  }, [city]);

  useEffect(() => {
    setInterval(function () {
      setDate(new Date());
    }, 1000);
  }, []);

  return (
    <Jumbotron className="jumbo-clock">
      <video autoPlay loop muted className="background-video">
        <source src={clip} type="video/mp4" />
        <source src={clip} type="video/ogg" />
      </video>
      <Row className="clock-top-row">
        <Col align="center">
          <h2 className="clock-text">
            بِسْمِ اللَّـهِ الرَّحْمَـٰنِ الرَّحِيمِ
          </h2>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <h3 className="clock-text">{hijriDate}</h3>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <h1 className="clock-text">{city}</h1>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <h3 className="clock-text">
            {date
              .toLocaleString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")}
          </h3>
          <h3 className="clock-text">{date.toLocaleTimeString()}</h3>
        </Col>
      </Row>
    </Jumbotron>
  );
}

export default ClockHero;
