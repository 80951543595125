import { React, useState, useEffect } from "react";

import {
  Row,
  Col,
  ListGroup,
  Container,
  Modal,
  Spinner,
  Button,
} from "react-bootstrap";
import "./PrayTime.css";
import axios from "axios";
import Progress from "./Progress";

function PrayTime({ city }) {
  const [times, setTimes] = useState(null);
  const [hijri, setHijri] = useState("");
  const [totalPrays, setTotalPrays] = useState([]);
  const [show, setShow] = useState(false);
  const [aya, setAya] = useState("");
  const [selected, setSelected] = useState({
    fajr: false,
    duhur: false,
    asr: false,
    maghrib: false,
    isha: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const doPray = (prayName) => {
    let newPrays = totalPrays;
    if (newPrays.includes(prayName)) {
      return;
    }
    newPrays.push(prayName);
    if (newPrays.length === 5) {
      handleShow();
      getAya();
    }
    setTotalPrays([...newPrays]);
    updateSelectedPos(prayName);
  };

  const undoPray = (prayName) => {
    let newPrays = totalPrays;
    if (!newPrays.find((pray) => pray === prayName)) {
      return;
    }
    const itemIndex = newPrays.indexOf(prayName);
    newPrays.splice(itemIndex, 1);
    setTotalPrays([...newPrays]);
    updateSelectedNeg(prayName);
  };

  const updateSelectedPos = (prayName) => {
    let newSelected = selected;
    newSelected[prayName] = true;
    setSelected(newSelected);
  };

  const updateSelectedNeg = (prayName) => {
    let newSelected = selected;
    newSelected[prayName] = false;
    setSelected(newSelected);
  };

  const getAya = async () => {
    const rand = Math.floor(Math.random() * 6236) + 1;
    const req = await axios.get(`https://api.alquran.cloud/v1/ayah/${rand}`);
    setAya({
      aya: req.data.data.text,
      sura: req.data.data.surah.name,
      number: rand,
    });
  };

  const dealy = async (ms) => new Promise((res) => setTimeout(res, ms));

  const resetAya = async () => {
    setAya("");
    await dealy(10000);
    await getAya();
  };

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        `https://api.aladhan.com/v1/timingsByCity?city=${city}&country=Israel&method=8`
      );

      setHijri(res.data.data.date.hijri.date);
      let timeObj = res.data.data.timings;
      let prayObj = [
        { Fajr: timeObj.Fajr },
        { Duhur: timeObj.Duhur },
        { Asr: timeObj.Asr },
        { Maghrib: timeObj.Maghrib },
        { Isha: timeObj.Isha },
      ];
      setTimes(res.data.data.timings);
    };
    getData();
  }, [city]);

  return (
    <div className="main">
      {times ? (
        <ListGroup className="praytime-list" variant="flush" dir="rtl">
          <ListGroup.Item className="pray-time fajr">
            <Row>
              <Col align="center">الفجر</Col>
              <Col align="center">
                {times.Fajr}
                {totalPrays.includes("fajr") ? (
                  <i className="fas fa-check-circle fa-md check-icon"></i>
                ) : (
                  <i className="em em-clock5 clock-icon"></i>
                )}
              </Col>
              <Col align="center">
                <i
                  className={
                    selected.fajr
                      ? "em em---1 emoji-icon"
                      : "em em---1 emoji-icon pulsating"
                  }
                  onClick={() => doPray("fajr")}
                ></i>
                <i className="em em-mosque emoji-icon alaqsa"></i>
                <i
                  className="em em--1 emoji-icon"
                  onClick={() => undoPray("fajr")}
                ></i>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="pray-time duhur">
            <Row>
              <Col align="center">الظهر</Col>
              <Col align="center">
                {times.Dhuhr}
                {totalPrays.includes("duhur") ? (
                  <i className="fas fa-check-circle fa-md check-icon"></i>
                ) : (
                  <i className="em em-clock1230 clock-icon"></i>
                )}
              </Col>
              <Col align="center">
                <i
                  className={
                    selected.duhur
                      ? "em em---1 emoji-icon"
                      : "em em---1 emoji-icon pulsating"
                  }
                  onClick={() => doPray("duhur")}
                ></i>
                <i className="em em-mosque emoji-icon alaqsa"></i>
                <i
                  className="em em--1 emoji-icon"
                  onClick={() => undoPray("duhur")}
                ></i>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="pray-time asr">
            <Row>
              <Col align="center">العصر</Col>
              <Col align="center">
                {times.Asr}
                {totalPrays.includes("asr") ? (
                  <i className="fas fa-check-circle fa-md check-icon"></i>
                ) : (
                  <i className="em em-clock430 clock-icon"></i>
                )}
              </Col>
              <Col align="center">
                <i
                  className={
                    selected.asr
                      ? "em em---1 emoji-icon"
                      : "em em---1 emoji-icon pulsating"
                  }
                  onClick={() => doPray("asr")}
                ></i>
                <i className="em em-mosque emoji-icon alaqsa"></i>
                <i
                  className="em em--1 emoji-icon"
                  onClick={() => undoPray("asr")}
                ></i>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="pray-time maghrib">
            <Row>
              <Col align="center">المغرب</Col>
              <Col align="center">
                {times.Maghrib}
                {totalPrays.includes("maghrib") ? (
                  <i className="fas fa-check-circle fa-md check-icon"></i>
                ) : (
                  <i className="em em-clock730 clock-icon"></i>
                )}
              </Col>
              <Col align="center">
                <i
                  className={
                    selected.maghrib
                      ? "em em---1 emoji-icon"
                      : "em em---1 emoji-icon pulsating"
                  }
                  onClick={() => doPray("maghrib")}
                ></i>
                <i className="em em-mosque emoji-icon alaqsa"></i>
                <i
                  className="em em--1 emoji-icon"
                  onClick={() => undoPray("maghrib")}
                ></i>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="pray-time isha">
            <Row>
              <Col align="center">العشاء</Col>
              <Col align="center">
                {times.Isha}
                {totalPrays.includes("isha") ? (
                  <i className="fas fa-check-circle fa-md check-icon"></i>
                ) : (
                  <i className="em em-clock9 clock-icon"></i>
                )}
              </Col>
              <Col align="center">
                <i
                  className={
                    selected.isha
                      ? "em em---1 emoji-icon"
                      : "em em---1 emoji-icon pulsating"
                  }
                  onClick={() => doPray("isha")}
                ></i>
                <i className="em em-mosque emoji-icon alaqsa"></i>
                <i
                  className="em em--1 emoji-icon"
                  onClick={() => undoPray("isha")}
                ></i>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      ) : (
        <Row>
          <Col align="center">
            <Spinner animation="border" role="status" className="pray-spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      <Progress prayes={totalPrays} />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Container>
            <Row>
              <Col align="center">
                <Modal.Title className="modal-title">
                  <i className="em em-prayer_beads masbaha"></i>
                  آيــة اليوم
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Row>
          <Col align="center">
            <Modal.Body className="modal-body">
              {aya ? (
                <Container>
                  <Row>
                    <Col align="center">
                      <h3 className="aya-header aya-text">{aya.aya}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col align="center" className="aya-details aya-text">
                      {aya.sura}, {aya.number}
                    </Col>
                  </Row>
                  <Row>
                    <Col align="center">
                      <audio controls>
                        <source
                          src={`https://cdn.islamic.network/quran/audio/64/ar.alafasy/${aya.number}.mp3`}
                          type="audio/ogg"
                        />
                      </audio>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Row>
                  <Col align="center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="pray-spinner"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              <Row>
                <Col align="center">
                  <Button onClick={resetAya} className="reset-aya">
                    آيــة اخرة
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default PrayTime;
