import { React, useState } from "react";
import { Container } from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import "./App.css";

import ClockHero from "./ClockHero.js";
import PrayTime from "./PrayTime.js";
import Footer from "./Footer.js";
import Navi from "./Navi.js";

function App() {
  const [city, setCity] = useState("Jerusalem");

  return (
    <Router>
      <Route path='/'>
    <Container fluid className="app-container">
      <Navi />
      <ClockHero city={city} />
      <PrayTime city={city} />
      <Footer citySelect={setCity} />
    </Container>
    </Route>
    </Router>
  );
}

export default App;
