import React from "react";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import "./Progress.css";

function Progress({ prayes }) {
  const now = 20;
  return (
    <Container className="progress-container">
      <Row>
        <Col align="center" className="progress-text">
          صلواتك من اجل الاقصى 
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <ProgressBar>
              {prayes &&
                prayes.map((pray, i) => (
                  <ProgressBar
                    striped
                    animated
                    now={20}
                    label={`${now}%`}
                    className={pray}
                    key={i}
                  />
                ))}
            </ProgressBar>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Progress;
