import { React } from "react";

import { Row, Col, Badge } from "react-bootstrap";
import "./Footer.css";

function Footer({ citySelect }) {
  const cities = [
    { city: "Acre", ar: "عكا" },
    { city: "Haifa", ar: "حيفا" },
    { city: "Jerusalem", ar: "القدس" },
    { city: "Jaffa", ar: "يافا" },
    { city: "Beersheba", ar: "بئر السبع" },
  ];

  return (
    <Row className="footer" fixed="bottom">
      <Col align="center" xs={12}>
        {cities.map((city, i) => (
          <Badge
            pill
            variant="primary"
            onClick={() => citySelect(city.city)}
            className="city-badge"
            key={i}
          >
            {city.ar}
          </Badge>
        ))}
      </Col>
    </Row>
  );
}

export default Footer;
